<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="6">
					<a-select
						show-search
						:placeholder="l('接口')"
						option-filter-prop="children"
						style="width: 100%"
						v-model="interfaceId"
						allowClear
						@change="getData"
					>
						<a-select-option v-for="item in interfaceList" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
					<!--                            <a-tree-select class="left"-->
					<!--                                           @change="getData"-->
					<!--                                           allowClear-->
					<!--                                           :placeholder="l('接口')"-->
					<!--                                           tree-default-expand-all-->
					<!--                                           style="width: 100%"-->
					<!--                                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
					<!--                                           :tree-data="interfaceList"-->
					<!--                                           v-model="interfaceId"-->
					<!--                            />-->
				</a-col>
				<a-col :span="12"> </a-col>
				<a-col :span="6" style="text-align: right">
					<a-input-search
						style="width: 200px"
						name="filterText"
						v-model="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="getData"
						enterButton
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1000, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<span slot="actions" slot-scope="text, record">
					<a-space>
						<!-- 修改 -->
						<a class="table-edit" @click="edit(record)" v-if="isGranted('interface_authorize_detail_edit')">
							<a-icon type="edit" />{{ l('Edit') }}</a
						>
					</a-space>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { DataRelationServiceProxy, InterfaceServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import CreateOrEditInterfaceAuthorize from '../roles/interface-authorize/create-or-edit-interface-authorize/create-or-edit-interface-authorize';

export default {
	name: 'interface-authorize-detail',
	mixins: [AppComponentBase],
	data() {
		return {
			// 表格
			columns: [
				{
					title: this.l('接口名称'),
					dataIndex: 'interfaceName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'interfaceName' },
				},
				{
					title: this.l('名称'),
					dataIndex: 'name',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: this.l('用户/角色'),
					dataIndex: 'objectName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'objectName' },
				},
				{
					title: this.l('公式'),
					dataIndex: 'formula',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'formula' },
				},
				{
					title: this.l('创建人'),
					dataIndex: 'creatorUserName',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'creatorUserName' },
				},
				{
					title: this.l('创建时间'),
					dataIndex: 'creationTime',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'creationTime' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: 150,
				},
			],

			tableData: [],
			interfaceId: undefined,
			interfaceList: [],
		};
	},
	created() {
		this._dataRelationServiceProxy = new DataRelationServiceProxy(this.$apiUrl, this.$api);
		this._interfaceServiceProxy = new InterfaceServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.interfaceList = await this.getInterfaceList();
		this.getData();
	},
	methods: {
		// searchTree(inputValue, treeNode) {
		//     return treeNode.data.props.title.includes(inputValue)
		// },
		getData() {
			this.loading = true;
			this._dataRelationServiceProxy
				.getInterfaceAuthorizeDetailPage(
					this.interfaceId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD hh:mm')
							: '';
					});
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 获取接口集合
		 */
		getInterfaceList() {
			return new Promise((resolve) => {
				this.loading = true;
				this._interfaceServiceProxy
					.getInterfaceList()
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		edit(record) {
			modalHelper
				.create(
					CreateOrEditInterfaceAuthorize,
					{
						id: record.id,
						interfaceId: record.interfaceId,
						type: record.objectType,
						ObjectId: record.objectId,
						interfaceName: record.interfaceName,
					},
					{ width: '600px' }
				)
				.subscribe((res) => {
					if (res) {
						this.refresh();
					}
				});
		},
	},
};
</script>

<style scoped></style>
